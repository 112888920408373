import { template as template_054663162b424a7c9d0b723661007a5a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_054663162b424a7c9d0b723661007a5a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
