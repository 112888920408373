import { template as template_8f2ce59f5dee46ba958e817c09631800 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8f2ce59f5dee46ba958e817c09631800(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
