import { template as template_ee1cd58cd7d24bcd87a1a6cfc8b7c9f1 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_ee1cd58cd7d24bcd87a1a6cfc8b7c9f1(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
